import React from "react";

import Layout from "../../components/organisms/Layout/Layout";
import SEO from "../../components/atoms/SEO/SEO";
import Section from "../../components/organisms/Section/Section";
import PageWidth from "../../components/organisms/PageWidth/PageWidth";

const NewsletterUnsubscribe = () => (
  <Layout>
    <SEO
      title="Newsletter abgemeldet"
      description="Du hast Dich erfolgreich abgemeldet"
    />
    <Section>
      <PageWidth>
        <h1>Du hast Dich erfolgreich vom Newsletter abgemeldet.</h1>
        <p>
          Danke für die schöne Zeit mit Dir...{" "}
          <span role="img" aria-label="Hand winkt">
            👋
          </span>
        </p>
      </PageWidth>
    </Section>
  </Layout>
);

export default NewsletterUnsubscribe;
